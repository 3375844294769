// 单个store
const user = {
  state: {
    isLogin: false,
    userName: "",
    loginTime: "",
    name: "",
    workCode: "",
    userid: 0,
  },
  getters: {
    GetIsLogin: (state) => state.isLogin,
    GetName: (state) => state.name,
    GetUserId: (state) => state.userid,
    GetWorkCode: (state) => state.workCode,
  },
  mutations: {
    SETUSERDATA (state, userData) {
      state.isLogin = userData;
    },
    SETUSERID (state, userid) {
      state.userid = userid;
    },
    SETNAME (state, name) {
      state.name = name;
    },
    SETWORKCODE (state, workCode) {
      state.workCode = workCode;
    },
  },
};

export default user;
